<template>
  <!--div class="hero" style="position: relative; height: auto">
    <img src="../assets/images/2023_2h_award.png" style="width: 100vw" />
  </div-->
  <div class="hero" style="position: relative; height: auto">
    <div
      class="min"
      id="hero-messages"
      style="position: absolute; top: 25%; left: 20%"
    >
      <img
        src="../assets/images/childu-txt.png"
        alt="차일드유가 영어를 책임지겠습니다."
        class="img-responsive hero-message col-8"
        data-slide="0"
        style="display: block; float: left; padding: 0px; border: 0px"
      />
      <div
        class="col-4 text-center"
        style="float: right; padding: 0px; border: 0px"
      >
        <a
          href="https://www.youtube.com/embed/FJH_xtyV_zI?modestbranding=1&amp;rel=0&amp;listType=playlist"
          class="lesson-demo-trigger"
          ><img
            src="../assets/images/hero-play-button-v1.webp"
            alt="Play button"
            class="img-responsive center-block play-btn"
            style="position: absolute; top: 460%; right: 25%"
        /></a>
      </div>
    </div>
    <video
      playsinline=""
      autoplay=""
      muted=""
      loop=""
      id="hero-video"
      style="filter: brightness(0.5); width: 100%"
    >
      <source
        src="https://dev.mypathkr.com/mp4/learning.mp4"
        type="video/mp4"
      />
    </video>
  </div>
  <div
    class="row noti-row"
    style="background: #f0f3fb; padding: 30px; border-top: 2px solid #d71318"
  >
    <h2
      style="
        color: #2e2e2e;
        font-size: 30px;
        font-weight: 900;
        margin-bottom: 20px;
      "
    >
      NOTICE
    </h2>
    <div class="card-body">
      <div class="container">
        <div
          class="col-md-6 noti-col"
          style="padding: 0 15px; background-color: #fff"
        >
          <div class="card-header">
            <p class="noti-p" style="color: #0068ff">ChildU NEWS</p>
            <p class="noti-more">
              <router-link to="./notice">+</router-link>
            </p>
          </div>
          <table class="table table_notice">
            <colgroup>
              <col width="80%" />
              <col width="20%" />
            </colgroup>
            <tr v-for="announce in announces" v-bind:key="announce.id">
              <td class="main-title">
                <router-link :to="'/articles/' + announce.id">{{
                  announce.title
                }}</router-link>
              </td>
              <td>{{ announce.created_at }}</td>
            </tr>
          </table>
        </div>
        <div
          class="col-md-6 noti-col"
          style="padding: 0 15px; background-color: #fff"
        >
          <div class="card-header">
            <!--<p class="noti-p" style="color:#6610f2;">{{ this.$root.name }} 공지사항</p>-->
            <p class="noti-p" style="color: #ff007a">Campus NEWS</p>

            <p class="noti-more">
              <router-link to="./campus_notice">+</router-link>
            </p>
          </div>
          <table class="table table_notice">
            <colgroup>
              <col width="80%" />
              <col width="20%" />
            </colgroup>
            <tr v-for="enterprise in enterprises" v-bind:key="enterprise.id">
              <td class="main-title">
                <router-link :to="'/articles/' + enterprise.id">{{
                  enterprise.title
                }}</router-link>
              </td>
              <td>{{ enterprise.created_at }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row gallery-row">
    <div class="card mb-4 container" style="padding: 30px 0px">
      <div class="card-header" style="color: #2e2e2e">
        <h2
          style="
            color: #20c997;
            font-size: 30px;
            font-weight: 900;
            margin-bottom: 0 !important;
          "
        >
          GALLERY
          <router-link to="./gallery"
            ><p
              style="display: inline-block; font-size: 35px; font-weight: 300"
            >
              +
            </p></router-link
          >
        </h2>

        <!--<p style="font-size: 15px;">우리 학원의 생생한 이야기</p>-->
      </div>
      <div class="card-body gallery-body">
        <div class="row">
          <!--
          <ul class="bxslider3" style="display: none">
            <li>
              <img src="http://placehold.it/730x200&text=FooBar1" />
            </li>
            <li>
              <img src="http://placehold.it/730x200&text=FooBar2" />
            </li>
            <li>
              <img src="http://placehold.it/730x200&text=FooBar3" />
            </li>
            <li>
              <img src="http://placehold.it/730x200&text=FooBar4" />
            </li>
            <li>
              <img src="http://placehold.it/730x200&text=FooBar5" />
            </li>
            <li>
              <img src="http://placehold.it/730x200&text=FooBar6" />
            </li>
          </ul>
        -->
          <ul class="bxslider">
            <li
              v-for="website in websites"
              v-bind:key="website.id"
              style="
                float: left;
                border: 1px solid #e4e4e4;
                margin: 0px 10px 10px 0px;
                border-radius: 4px;
              "
            >
              <router-link :to="'/articles/' + website.id">
                <div
                  class="img-box"
                  style="
                    height: 130px;
                    width: 200px;
                    overflow: hidden;
                    margin: 4px;
                  "
                >
                  <div
                    v-html="website.content"
                    class="test_img"
                    style="height: 130px; margin: 0 auto"
                  ></div>
                </div>
                <div
                  style="
                    padding-top: 6px;
                    color: #2e2e2e;
                    font-size: 13px;
                    font-weight: 500;
                  "
                >
                  <router-link :to="'/articles/' + website.id">{{
                    website.title
                  }}</router-link>
                </div>
                <div style="font-size: 12px; margin-bottom: 10px">
                  {{ website.created_at }}
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div style="background: #f9f9f9">
    <div class="card-header" style="padding-top: 40px">
      <h2
        style="
          color: #0dcaf0;
          font-size: 30px;
          font-weight: 900;
          margin-bottom: 20px;
        "
      >
        CAMPUS QUICK LINK
      </h2>
    </div>

    <div class="row" style="padding-bottom: 40px">
      <div class="container quick-contain" style="padding-left: 30px">
        <div class="col-sm-4" v-for="banner in banners" v-bind:key="banners.id">
          <a :href="banner.addi_data2" target="_blank">
            <img
              :src="banner.addi_data3"
              style="
                border-radius: 30px;
                width: 300px;
                height: 150px;
                overflow: hidden;
              "
            />
          </a>
        </div>
      </div>
    </div>
  </div>
  <div id="benefits" class="gray-stripe rank-contain" style="padding: 20px 0">
    <div class="container">
      <div class="card-header" style="color: #103674">
        <h2>
          <img
            class="rank-title"
            src="../assets/images/today-rank_b.png"
            style="width: 400px; margin: 0 auto"
          />
        </h2>
        <h3
          style="
            font-size: 17px;
            font-weight: 600;
            margin-bottom: 20px !important;
          "
        >
          오늘도 공부 열심히 했나요? 오늘의 난 몇 등?
        </h3>
        <!--<p style="font-size: 15px; color:#2e2e2e;">American School과 Language Training를 통해서 얻은 Alpha점수를 지금 확인해보세요!</p>-->
      </div>

      <div class="row rank-row" id="prods">
        <div
          v-for="(avatar, i) in avatars"
          v-bind:key="avatar.id"
          class="col-sm-2 avatar-bg"
          id="baseListing"
          style="
            border-top: 4px solid #0c3674;
            border-bottom: 4px solid #0c3674;
          "
        >
          <div
            style="
              z-index: 1;
              top: 25px;
              width: 24px;
              text-align: center;
              left: 33px;
              position: absolute;
              color: #fff;
            "
          >
            <span
              style="
                background: #103674;
                padding: 2px 10px;
                border-radius: 40px;
                color: #fff;
              "
              >{{ i + 1 }}</span
            >
          </div>
          <div
            style="
              margin: 0 auto;
              text-align: center;
              background: url('https://www.mypathkr.com/img/ranking_medal.png')
                no-repeat;
              margin-top: 15px;
            "
          >
            <img
              :id="`Avatar_${avatar.id}`"
              :src="avatar.img_src"
              style="width: 100%"
            />
            <!--img :src="loadAvatarNew(`Avatar_${avatar.id}`, avatar.id)" style="width: 60%;" -->
            <!--/div-->
            <div style="margin: 0 auto">
              <div style="display: flex; margin: 5px 5px">
                <div
                  style="
                    border-radius: 10px 0 0 10px;
                    background: #103674;
                    width: 30%;
                    height: 30px;
                    line-height: 30px;
                    color: #fff;
                    font-size: 11px;
                  "
                >
                  NAME
                </div>
                <div
                  style="
                    border-radius: 0 10px 10px 0;
                    background: #f0f0f0;
                    width: 70%;
                    height: 30px;
                    line-height: 30px;
                    color: #103674;
                    font-size: 12px;
                  "
                >
                  {{ avatar.name }}
                </div>
              </div>
              <div style="display: flex; margin: 5px 5px">
                <div
                  style="
                    border-radius: 10px 0 0 10px;
                    background: #103674;
                    width: 30%;
                    height: 30px;
                    line-height: 30px;
                    color: #fff;
                    font-size: 12px;
                  "
                >
                  <img
                    width="32"
                    v-bind:src="`https://bizstudy.useschool.co.kr/img/emblem/${avatar.tier_tag}`"
                    alt=""
                  />
                </div>
                <div
                  style="
                    border-radius: 0 10px 10px 0;
                    background: #f0f0f0;
                    width: 70%;
                    height: 30px;
                    line-height: 30px;
                    color: #333;
                    font-weight: bold;
                    font-size: 13px;
                  "
                >
                  {{ avatar.alpha.toLocaleString() }} 점
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" style="padding: 30px 0; background-color: #fff">
    <div class="card mb-4 container" style="font-size: 14px">
      <div class="card-body">
        <div class="container event-contain">
          <div class="col-sm-4 event-col">
            <div class="card-header">
              <h2 style="font-size: 30px; font-weight: 900; margin: 10px 0">
                EVENT
              </h2>
              <!--<p style="font-size: 15px;">지금 차일드유에서 진행하는 이벤트는?</p>-->
            </div>
            <a
              href="https://mypathkr.com/sub/event/2023/2024_winterfestival_awards.asp"
            >
              <img
                src="../assets/images/2024_winterfestival_intro_bn_awards.png"
                style="border-radius: 20px"
              />
            </a>
          </div>

          <div
            class="col-sm-8 noti-col"
            style="
              padding: 10px;
              border-radius: 30px;
              boder: 1px solid #d6dbe6;
              box-shadow: 0px 0px 20px -5px #d6dbe6;
              min-height: 100px !important;
            "
          >
            <h2
              style="
                font-size: 30px;
                font-weight: 900;
                margin-bottom: -5px;
                border-bottom: 1px dashed #ececec;
                padding-bottom: 15px;
              "
            >
              INFORMATION
            </h2>

            <div class="col-sm-3 info-icon">
              <a href="/info"
                ><img
                  src="../assets/images/icon_map.png"
                  style="width: 80px; margin-top: 15px"
                /><br />
                <p
                  class="info-p"
                  style="
                    margin-top: 10px;
                    margin-bottom: 0px;
                    font-size: 15px;
                    font-weight: 400;
                    color: #2e2e2e;
                  "
                >
                  위치안내
                </p>
              </a>
            </div>
            <div class="col-sm-3 info-icon">
              <a href="/curri"
                ><img
                  src="../assets/images/icon_curriculum.png"
                  style="width: 80px; margin-top: 15px"
                /><br />
                <p
                  class="info-p"
                  style="
                    margin-top: 10px;
                    margin-bottom: 0px;
                    font-size: 15px;
                    font-weight: 400;
                    color: #2e2e2e;
                  "
                >
                  커리큘럼
                </p>
              </a>
            </div>
            <div class="col-sm-3 info-icon">
              <a
                href="http://mypathkr.com/data/230523차일드유_기술시스템가이드.pdf"
                target="_blank"
                ><img
                  src="../assets/images/icon_guide.png"
                  style="width: 80px; margin-top: 15px"
                /><br />
                <p
                  class="info-p"
                  style="
                    margin-top: 10px;
                    margin-bottom: 0px;
                    font-size: 15px;
                    font-weight: 400;
                    color: #2e2e2e;
                  "
                >
                  학습관 가이드
                </p>
              </a>
            </div>
            <div class="col-sm-3 info-icon">
              <a href=""
                ><img
                  src="../assets/images/icon_admission.png"
                  style="width: 80px; margin-top: 15px"
                /><br />
                <p
                  class="info-p"
                  style="
                    margin-top: 10px;
                    margin-bottom: 0px;
                    font-size: 15px;
                    font-weight: 400;
                    color: #2e2e2e;
                  "
                >
                  입학 문의
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- popup Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog"
      role="document"
      style="width: 380px; top: 80px; left: -700px"
    >
      <div
        class="modal-content"
        style="
          background: none;
          box-shadow: none !important;
          border: 0;
          width: 380px;
        "
      >
        <div class="bxslider" id="listings">
          <div>
            <a href="{link}" target="{target}"
              ><img src="{imageurl}" style="width: 100%" />
            </a>
          </div>
        </div>

        <div class="modal-body" style="padding: 0">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
            style="
              position: absolute;
              right: 10px;
              bottom: 8px;
              padding: 2px 6px;
              background: none;
              border: none;
              color: #ededed;
            "
          >
            X
          </button>
          <div
            style="
              width: 380px;
              height: 40px;
              background: #000;
              color: #fff;
              line-height: 40px;
              padding: 0px 10px;
              line-height: 40px;
              border-radius: 0px 0px 10px 10px;
            "
          >
            <input
              type="checkbox"
              style="width: 15px; height: 15px; margin: 2px"
              @click="no_watch_today()"
              data-bs-dismiss="modal"
            />
            오늘 하루 그만 보기
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import axios from "axios";

export default {
  data: () => ({
    boards: {},
    articles: [],
    selBoard: "",
    announces: [],
    enterprises: [],
    websites: [],
    events: [],
    avatars: [],
    banners: [],
    popups: [],
    isModalVisible: false,
  }),
  name: "Home",
  components: {
    HelloWorld,
  },
  methods: {
    loadAvatarNew(divid, uid) {
      const loadImage = (url) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.setAttribute("crossorigin", "anonymous");
          img.addEventListener("load", () => resolve(img));
          img.addEventListener("error", (err) => reject(err));
          img.src = url + "?nocache2";
        });
      return new Promise((resolve, reject) => {
        $.ajax({
          type: "GET",
          url: "https://api-bizavatar.useschool.co.kr/retrieveInfouse.php",
          dataType: "json",
          timeout: 15000,
          cache: false,
          data: { uid: uid },
          success: function (result) {
            //console.log (result);
            if (result.status == "ok") {
              var promises = [];
              if (result.gender == "2") {
                promises.push(
                  loadImage(
                    "https://avatar.useschool.co.kr/avatar/img/girl.png"
                  )
                );
              } else {
                promises.push(
                  loadImage(
                    "https:///avatar.useschool.co.kr/avatar/img/boy.png"
                  )
                );
              }

              if (result.face && result.face.item) {
                promises.push(
                  loadImage(
                    "https://avatar.useschool.co.kr/avatar/img_2/avatarshop-main/face/" +
                      result.face.item.code
                  )
                );
              }

              if (result.hair.item) {
                promises.push(
                  loadImage(
                    "https://avatar.useschool.co.kr/avatar/img_2/avatarshop-main/hair/" +
                      result.hair.item.code
                  )
                );
              }
              if (result.shoes.item) {
                promises.push(
                  loadImage(
                    "https://avatar.useschool.co.kr/avatar/img_2/avatarshop-main/shoes/" +
                      result.shoes.item.code
                  )
                );
              }
              if (result.clothes.item) {
                promises.push(
                  loadImage(
                    "https://avatar.useschool.co.kr/avatar/img_2/avatarshop-main/clothes/" +
                      result.clothes.item.code
                  )
                );
              }
              if (result.weapon.item) {
                promises.push(
                  loadImage(
                    "https://avatar.useschool.co.kr/avatar/img_2/avatarshop-main/weapon/" +
                      result.weapon.item.code
                  )
                );
              }
              if (result.pet.item) {
                promises.push(
                  loadImage(
                    "https://avatar.useschool.co.kr/avatar/img_2/avatarshop-main/pet/" +
                      result.pet.item.code
                  )
                );
              }
              Promise.all(promises).then((results) => {
                var canvas = document.createElement("canvas");

                canvas.width = 500;
                canvas.height = 550;
                if (canvas.getContext) {
                  var draw = canvas.getContext("2d");
                  results.forEach((v, i) => {
                    if (v == null) return;
                    //이미지의 원하는 부분만 잘라서 그리기
                    //drawImage(이미지객체,
                    //        이미지의 왼위 부분x, 이미지의 왼위 부분y, 이미지의 원하는 가로크기, 이미지의 원하는 세로크기,
                    //        사각형 부분x, 사각형 부분y, 가로크기, 세로크기)
                    //draw.drawImage(img, 100,100, 300,300, 50,50, 250,300);

                    //전체 이미지 그리기
                    //drawImage(이미지객체, 사각형 왼위 x, 사각형 왼위 y, 가로크기, 세로크기)
                    draw.drawImage(v, 0, 0, 500, 550);
                  });
                }
                canvas.toBlob(function (blob) {
                  var bloburl = URL.createObjectURL(blob);
                  console.log(bloburl);
                  resolve(bloburl);
                });
              });
            }
          },
          error: function (e) {
            console.log("Data error: " + e);
          },
        });
      });
    },
    async fetchData() {
      try {
        const responsePopups = await axios.get(this.$root.baseUrl + "/popups/usersite");
        this.popups = responsePopups.data.result;
        if (this.popups.length) {
          const base = document.getElementById("listings").innerHTML;
          let resHtml = "";
          this.popups.forEach((element) => {
            resHtml += base
              .replace('{link}', element.link_href)
              .replace('{target}', element.target == "_blank" ? "_blank" : "")
              .replace('{imageurl}', element.image_url);
          });
          document.getElementById("listings").innerHTML = resHtml;
          this.isModalVisible = true;
          
          if (this.popups.length > 1) {
           this.initializeSlider();
          }
        }
      } catch (error) {
        console.log("Error loading data:", error);
      }
    },
    initializeSlider() {
      if ($.fn.bxSlider) {
        $('#listings').bxSlider({
          auto: true,
          stopAutoOnClick: true,
          controls: true,
          pager: true,
          touchEnabled: false,
          slideWidth: 380,
          speed: 600,
          pause: 3500,
          minSlides: 1,
          maxSlides: 1,
          moveSlides: 1,
          infiniteLoop: true,
          responsive: true,

        });
      }
    },
  },
  mounted() {
    this.fetchData();
    //공지사항
    axios
      .get(this.$root.baseUrl + "/articles_new?origin=usersite&boards=announce")
      .then((response) => {
        if (response.data.error) {
          //alert("게시판 리스트 로드 실패\n" + response.data.error)
        } else {
          this.announces = response.data.result;
          //console.log(this.announces)
        }
      }),
      //캠퍼스공지사항
      axios
        .get(
          this.$root.baseUrl +
            "/articles_new?origin=usersite&boards=enterprise&userid=" +
            this.$root.id
        )
        .then((response) => {
          if (response.data.error) {
            //alert("게시판 리스트 로드 실패\n" + response.data.error)
          } else {
            this.enterprises = response.data.result;
            //console.log(this.announces)
          }
        }),
      //캠퍼스 갤러리
      axios
        .get(
          this.$root.baseUrl +
            "/articles_gaelleoli?origin=usersite&boards=gaelleoli_website&limit=10&userid=" +
            this.$root.id
        )
        .then((response) => {
          if (response.data.error) {
            //alert("게시판 리스트 로드 실패\n" + response.data.error)
          } else {
            this.websites = response.data.result;
            //console.log(this.announces)
          }
        }),
      //avatar
      axios
        .get(
          this.$root.baseUrl +
            "/listEnterprise?origin=usersite&username=" +
            this.$root.name
        )
        .then((response) => {
          if (response.data.error) {
            //alert("게시판 리스트 로드 실패\n" + response.data.error)
          } else {
            this.avatars = response.data.result;
            this.avatars.forEach(async (v, i) => {
              v.img_src = await this.loadAvatarNew(null, v.id);
            });
            //console.log(this.announces)
          }
        }),
      //banner
      axios
        .get(
          this.$root.baseUrl +
            "/articles_banner?origin=usersite&boards=banner&userid=" +
            this.$root.id
        )
        .then((response) => {
          if (response.data.error) {
            //alert("게시판 리스트 로드 실패\n" + response.data.error)
          } else {
            this.banners = response.data.result;
            //console.log(this.announces)
          }
        }),
      $(".bxslider3").bxSlider({
        minSlides: 2,
        maxSlides: 5,
        slideWidth: 200,
        slideMargin: 10,
      });

    axios
      .get("https://api.mypathkr.com/articles?boards=2022_summer&mode=non_exp")
      .then((response) => {
        this.events = response.data.result;
      });
  },
  watch: {
    isModalVisible(newVal) {
      if (newVal) {
        $('#exampleModal').modal('show');
      } else {
        $('#exampleModal').modal('hide');
      }
    },
  },
};
</script>

<style >
  #listings .bxslider {
    display: none;
  }
  .bx-wrapper {
      max-width: 100%;
      width: 100%; 
      margin: 0 auto;
      display: block;
  }
  .bx-controls {
      display: block; /* 또는 flex, inline-block 등 필요에 따라 설정 */
  }

  .bx-controls-direction {
      display: block ; /* 컨트롤 표시 */
  }

  .bx-pager {
    display:  block !important; /* 컨트롤 표시 */
      justify-content: center; /* 가로 방향 중앙 정렬 */
      margin: 40px 0;        /* 상하 여백 추가 (필요 시) */
      width: 100%; 
      
  }
  .bx-pager-item {
      margin: 0 5px; /* 좌우 여백 조정 */
  }

  .bx-pager-link {
      padding: 5px 10px; /* 링크에 패딩 추가 */
  }

</style>
